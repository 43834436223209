import React from 'react';
import * as styles from './case.module.scss';

const ankerUserInfo = ({ temp, more=true }) => {
  return (
    <div className="case">
      {
        temp.title?
        <p className={styles.title}>{temp.title}</p>
        :
        null
      }
      <div className="userIntroContainer">
        {
          temp.content.map((item, i) => (
              <div key={i} className={styles.containerWrap}>
                <div className={`${styles.photo}`}>
                  <div className={`hoverPhoto`}>
                    <img src={item.image}></img>
                  </div>
                  <p className={styles.caseTitle}>{item.caseTitle}</p>
                  <p className={styles.caseSubTitle}>{item.caseSubTitle}</p>
                </div>
                <div className={styles.content}>
                  <div dangerouslySetInnerHTML={{ __html: item.text }} ></div>
                </div>
              </div>
          ))
        }
      </div>
    </div>
  );
};

export default ankerUserInfo;
