import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Sidebar from '../../components/universities/sidebar';
import Case from '../../components/project/case';
import * as styles from './EntrepreneurCase.module.scss';

class EntrepreneurCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const temp = {
      banner: 'http://anker-in.oss-cn-zhangjiakou.aliyuncs.com/production/screen_shot_2020_06_08_at_10_52_47_am_739bb730a2.jpeg',
      section1: {
        id: ['001', '002', '003'],
        content: [
          {
            id: 1,
            caseTitle: '通信老兵的二次创业',
            caseSubTitle: 'Frank',
            image: 'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/story_Frank_2984655393.jpeg',
            text: "<p class='caseTips'>这里最好的就是全价值链的赋能体系，你可以放心勇往直前，这里的成功没有天花板，我看到了无限可能。</p><br/><p>2000年毕业后在酷派工作16年，历任360手机总裁、酷派互联网及电商总裁，负责过多个智能终端产品全球开创性技术的研发，首款双模双待智能手机关键技术创始人，曾创造大神手机。</p><br/><p>2016年加入安克创新，担任安克创新子公司智新总裁，带领团队继续深耕移动智能终端业务，负责安克创新的IOT和SE两大BG。研发方向从以往的智能手机转移到了智能安防设备、智能投影仪、智能会议系统、智能机器人等智能物联网产品的开发，产品实现超过20亿人民币的销售额。</p>",
          },
          // {
          //   "id": 2,
          //   "caseTitle": "从GM到副总裁",
          //   "caseSubTitle": "William",
          //   "image": "https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/story_William_31d5a70bb3.jpeg",
          //   "text": "<p class='caseTips'>我相信很多人都有创业的梦想，但是害怕失败迟迟迈不开步伐，安克创新是出海智能硬件创业最好的平台，九败一胜也不畏惧。</p><br/><p>曾在华为、施耐德、飞利浦等公司担任系统架构、研发、技术创新等相关职能的负责人，拥有丰富的产品研发和管理经验。</p><br/><p>2015年加入公司负责研发部门，完成了200多个研发项目从立项到量产的研发工作。2016年公司事业部改革后，任Appliances BG负责人，从0到1开拓了智能家居品类，3年内使eufy成为北美、欧洲、日本等市场TOP级的品牌，其中扫地机产品位列多个市场销售量和用户体验领先位置，2019年销售额突破10亿人民币。</p><br/><p>2020年5月，成为首个安克创新内部提拔的副总裁。</p>",
          // },
          {
            id: 3,
            caseTitle: '产品精英的10年磨砺',
            caseSubTitle: 'Alex',
            image: 'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/story_Alex_43ceec2069.jpeg',
            text: "<p class='caseTips'>优秀的产品经理都有操盘10亿规模业务和成长为事业部总经理的机会，价值创造决定价值分配，10年我最不缺的是机会。</p><br/><p>2011年加入Anker，从应届毕业生一路升级打怪做到产品总监兼产品线总经理（PDT leaer）。作为公司最早的产品经理，Alex负责了Anker充电宝、数据线等多条核心业务线从0-1的建立，目前相关业务线的销售额稳定在30亿人民币以上。</p><br/><p>其中带领团队做出的“Power Line+”系列数据线，弯折次数从行业平均的1000次升级到最高35000次，耐用度不断突破极限，打造了风靡全球的爆款“拉车线”。</p>",
          },
          {
            id: 4,
            caseTitle: '“互相成就”的产品梦想',
            caseSubTitle: 'Larry',
            image: 'https://anker-in.oss-cn-zhangjiakou.aliyuncs.com/development/story_Larry_79015b3613.jpeg',
            text: "<p class='caseTips'>我们一直坚持为用户创造价值，做创新的产品，这是我理想的产品公司的态度。</p><br/><p>2016年带着7年海外智能硬件产品经验加入公司，担纲安防品类的产品创新研发，从0到1开拓了eufy Security产品线，构建了整套智能安防软硬件和服务生态及参与一整套复杂产品系统的开发。</p><br/><p>eufyCam在美国Kickstarter平台众筹成交313万美金，创造全球智能家居和安防类产品众筹记录第一名，2019年成为最早进入苹果智慧家庭生态的智能硬件品牌之一，目前已经进入美国top5、西欧top3、澳大利亚top2。</p>",
          },
        ],
      },
      section2: [
        {
          text: '项目简介',
          url: '/project/introduction',
        },
        {
          text: '项目亮点',
          url: '/project/highlights',
        },
        {
          text: '职位信息',
          url: '/project/job',
        },
        {
          text: '创业者案例',
          url: '/project/entrepreneurcase',
        },
      ],
      seo: {
        title: '安克创新招聘.安克创新校园招聘.加入安克创新',
        keywords: ['安克创新招聘', '长沙上市公司招聘', '跨境电商公司招聘', '长沙互联网公司招聘', 'Anker招聘', '智能硬件出海公司招聘'],
        description: '作为标杆出海企业，安克创新致力于在全球市场塑造中国消费电子品牌，通过不断创新，将富有科技魅力的领先产品带向全球消费者，弘扬中国智造之美。安克创新现成功打造了3个年营收过15亿的出海品牌，拥有全球100多个国家与地区的7500万用户。',
      },
    };
    const { data, location } = this.props;
    const copywriting = data.contentfulCopywriting;

    const { pathname } = location || {};
    return (
      <div className={styles.home}>
        <Layout {...this.props} hideSearch={true} copywriting={copywriting}>
          <SEO title={temp.seo.title} keywords={temp.seo.keywords} description={temp.seo.description} />
          <div className={`projectIntroduction ${styles.mt81}`}>
            <Sidebar pathname={pathname} temp={temp.section2}></Sidebar>
            <Case temp={temp.section1} more={false}></Case>
          </div>
        </Layout>
      </div>
    );
  }
}

export default EntrepreneurCase;

export const query = graphql`
query entrepreneurQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    footer {
      brands {
        link
        iconfont
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
        children {
          menuId
          name
          url
        }
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
  }
} 
`;


